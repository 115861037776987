import AbstractResource from './AbstractResource'
import {
  required, email, sameAs, minLength
} from 'vuelidate/lib/validators'

class User extends AbstractResource {
   current_password = null;
   password = null;
   password_confirmation = null;

   constructor ($http, data, isFull) {
     if (!data) {
       data = { id: null, email: null, institution: null, id_number: null, id_type: null, roles: [] }
     }
     super($http, data, isFull)
   }

   getValidationRules () {
     return User.validations
   }

   getPasswordChangeRules () {
     return {
       current_password: { required },
       password: {
         required,
         minLength: minLength(8)
       },
       password_confirmation: {
         required,
         match: sameAs('password')
       }
     }
   }

   getFullValidationRules () {
     return Object.assign(this.getValidationRules(), this.getPasswordChangeRules())
   }

   create (context) {
     const data = {
       email: this.email,
       institution: this.institution,
       name: this.name,
       title: this.title
     }

     return this.$http({
       method: 'post',
       url: context + '/users',
       data: data
     }).then(response => {
       this.id = response.data.user_id

       return new Promise((resolve) => {
         resolve(response)
       })
     })
   }

   addRole (context, role, event_id) {
     return this._updateRole(context, role, event_id, 'post').then(response => {
       this.roles.push({ name: role, event_id })
     })
   }

  removeRole (context, role, event_id) {
    return this._updateRole(context, role, event_id, 'delete').then(response => {
      this.roles.splice(this.roles.indexOf(role), 1)
    })
  }

  _updateRole (context, role, event_id, verb) {
    if (this.isNew()) {
      return Promise.reject(new Error('The user is not registered yet.'))
    }

    const data = { role: role }

    if (event_id) {
      data.event_id = event_id
    }

    return this.$http({
      url: `${context}/users/${this.id}/roles`,
      method: verb,
      data
    })
  }

  getRoleNames () {
    const roleNames = this.roles.map(role => role.name)
    // return unique Role names
    return roleNames.filter((role, index) => roleNames.indexOf(role) === index)
  }

  update (fields) {
     let data = this

     if (fields) {
       data = {}
       fields.forEach((field) => {
         data[field] = this[field]
       })
     }

     return this.$http.put(this.getUri(), data)
   }
}

function $gettext (txt) {
  return txt
}

User.validations = {
  name: { required },
  email: { required, email }
  // institution: {required}
}

User.ROLES = [
  { key: 'participant', value: $gettext('Participant'), cssLabel: 'label-info' },
  { key: 'reviewer', value: $gettext('Reviewer'), cssLabel: 'label-warning' },
  { key: 'editor', value: $gettext('Editor'), cssLabel: 'label-primary' },
  { key: 'organizer', value: $gettext('Organizer'), cssLabel: 'label-primary' },
  { key: 'admin', value: $gettext('Admin'), cssLabel: 'label-danger' },
]

User.getRole = (name) => User.ROLES.find(role => role.key === name)

User.TITLES = ['Prof.', 'PhD', 'MsC', 'MA', 'BsC', 'BA']

export default User
